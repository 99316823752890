import { GET_FLOWERS, UPDATE_FLOWER_SELECTION, GET_SHOPIFY_PRODUCTS } from "../actions/types";
const initialState = {
    flowers: {},
    flowersUpdate: {},
    products: {}
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_FLOWERS:
            return {
                ...state,
                flowers: action.payload
            };

        case UPDATE_FLOWER_SELECTION:
            return {
                ...state,
                flowersUpdate: action.payload
            };

        case GET_SHOPIFY_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };

        default:
            return state;
    }
}