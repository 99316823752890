import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/site.min.css';
import HeaderTemplate from "./components/layout/Header";
import FooterTemplate from "./components/layout/Footer";
import BB_Overview   from "./components/pages/BB_Overview";
import Error404       from "./components/pages/Error404";

class App extends Component
{
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <React.Fragment>
                        <HeaderTemplate title="Bouquet Builder" />
                        <Switch>
                            <Route exact path="/" component={BB_Overview} />
                            <Route component={Error404} />
                        </Switch>
                        <FooterTemplate />
                    </React.Fragment>
                </Router>
            </Provider>
        );
    }
}

export default App;