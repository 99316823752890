import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Loading from "../layout/Loading";

import { getFlowers, resetFlowerSelection, updateFlowerSelection } from '../../client/actions/configActions';
import { getProducts } from '../../client/apis/shopifyProducts';
import Client from "shopify-buy";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const client = Client.buildClient({
    // LIVE CODE
    storefrontAccessToken: 'c9b98b88961c0862cea503f4e985bdae',
    domain: 'overnight-flowers.myshopify.com'
});
const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzEzMTEwMDY3MjA2NA==";

export class BB_Step1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            flowers: [],
            selectedFlowers: [],
            tmpSelectedFlowers: [],
            FlowerImage: '/assets/images/bb-logo.jpg',
            totalQty: 0,
            selectedFlower: '',
            flowersLoaded: false,
        };
        this.intervalHandle = null;
        this.startTimer = this.startTimer.bind(this);
        this.updateTimer = this.updateTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
    }
    componentDidMount() {
        if (typeof(this.props.selectedFlowers) !== 'undefined') {
            this.setState({ tmpSelectedFlowers: this.props.selectedFlowers, selectedFlowers: this.props.selectedFlowers });
            this.props.resetFlowerSelection(this.props.selectedFlowers);
        }
        if (typeof(this.props.totalQty) !== 'undefined') {
            this.setState({ totalQty: this.props.totalQty });
        }

        let flowers = [];
        client.collection.fetchWithProducts(collectionId).then((collectionResults) => {
            collectionResults.products.forEach((collection, index) => {
                if (collection.id !== 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNDkwOTc1NDE3MDE=') {
                    let colors = [];
                    collection.variants.forEach( (color, index) => {
                        colors.push({
                            shopifyId: color.id,
                            name: color.title,
                            image: color.image.src,
                            price: color.price,
                            sku: color.sku,
                            available: color.available
                        });
                    });
                    flowers = {
                        name: collection.title,
                        colors: colors,
                        productImage: collection.images[0].src
                    };
                    this.setState({ flowers: [...this.state.flowers, flowers] });
                }
            });
            this.setState({flowersLoaded: true });
        });
    }
    componentWillUnmount() {
        clearInterval(this.intervalHandle);
    }
    handleClick = (e, item, color, qty, flowerImage, flowerShopifyId) => {
        this.stopTimer();
        const { totalQty } = this.state;
        let newSelectedFlowers = [];
        let tSelectedFlowers = [];
        let tQty = 0;
        let skip = 0;
        if (totalQty < 36) {
            if (qty <= 0)
            {
                if (this.state.selectedFlowers !== null && typeof(this.state.selectedFlowers) !== 'undefined') {
                    tSelectedFlowers = this.state.selectedFlowers;
                    this.state.selectedFlowers.forEach((tFlower, index) => {
                        if (tFlower.item === item && tFlower.color === color && skip === 0) {
                            let tIndex = index;
                            tSelectedFlowers = tSelectedFlowers.filter((tFlower, index) => index !== tIndex);
                            skip=1;
                        }
                    });
                    newSelectedFlowers = [...tSelectedFlowers];
                }
            }
            else
            {
                if (this.state.selectedFlowers !== null && typeof(this.state.selectedFlowers) !== 'undefined') {
                    tSelectedFlowers = this.state.selectedFlowers;
                    this.state.selectedFlowers.forEach((tFlower, index) => {
                        if (tFlower.item === item && tFlower.color === color && skip === 0) {
                            let tIndex = index;
                            tQty = qty + tFlower.qty;
                            tSelectedFlowers = tSelectedFlowers.filter((tFlower, index) => index !== tIndex);
                            tSelectedFlowers.push({item: item, color: color, qty: qty, flowerImage: flowerImage, shopifyId: flowerShopifyId});
                            skip=1;
                        }
                    });
                    newSelectedFlowers = [...tSelectedFlowers];
                    if (skip === 0) {
                        newSelectedFlowers.push({item: item, color: color, qty: qty, flowerImage: flowerImage, shopifyId: flowerShopifyId});
                    }
                } else {
                    newSelectedFlowers.push({item: item, color: color, qty: qty, flowerImage: flowerImage, shopifyId: flowerShopifyId});
                }
            }

            tQty = 0;
            newSelectedFlowers.forEach(tFlower => {
                tQty = (tFlower.qty * 3) + tQty;
            });

            this.setState({
                selectedFlowers: newSelectedFlowers,
                totalQty: tQty
            });
            this.props.updateFlowerSelection( item, color, qty, newSelectedFlowers);
        }
        else {
            /*
            // add back in PHASE 2
            // checkoutUrl
            const options = {
                title: 'Cannot Add More Flowers',
                message: 'You can only add a maximum of 36 flowers to your bouquet.',
                buttons: [{
                    label: 'OK',
                    onClick: () => console.log("clicked")
                }],
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => {},
                onClickOutside: () => {},
                onKeypressEscape: () => {}
            };
            confirmAlert(options);

             */
        }
    };
    changeSelectedFlower = (flower) => {
        this.setState({selectedFlower:  flower.name});
    };
    changeSelectedFlowerSelect = (flower) => {
        this.setState({selectedFlower:  flower.value});
    };
    cancelChanges = () => {
        this.setState({selectedFlowers:  this.state.tmpSelectedFlowers});
        this.closeModal();
    };
    closeModal = () => {
      this.props.closeModal();
    };
    saveChanges = (FlowerImage) => {
        let flowerIds = this.props.flowersUpdate.FlowerIds;
        this.props.saveFlowerSelection(this.state.selectedFlowers, FlowerImage, this.state.totalQty, flowerIds);
    };



    // TIMER FUNCTIONS
    startTimer = (e, item, color, qty, flowerImage, flowerShopifyId) => {
        this.stopTimer();
        this.intervalHandle = setInterval(() => this.handleClick(e, item, color, qty, flowerImage, flowerShopifyId), 500);
    };
    updateTimer = (e, item, color, qty, flowerImage, flowerShopifyId) => {
        this.stopTimer();
    };
    stopTimer = () => {
        clearInterval(this.intervalHandle);
    };

    changeFlower = (selectedFlower) => {
        this.setState({
            selectedFlower: selectedFlower
        });
    };
    doneClicked = () => {
        this.setState({
            selectedFlower: ''
        });
    };

    render() {
        const { flowersUpdate } = this.props;
        const { flowers, selectedFlowers, totalQty,  selectedFlower, flowersLoaded } = this.state;

        let flowerOptions = [];
        let selectedFlowerSelect = {};
        if (flowersLoaded === true) {
            flowers.map( (flower) => {
                flowerOptions.push({ value: flower.name, label: flower.name, image: flower.productImage});
                return(null);
            });
            selectedFlowerSelect = {value: selectedFlower, label: selectedFlower}
        }

        if (flowers)
        {
            let FlowerImage = '';
            if (FlowerImage === '') FlowerImage = this.state.FlowerImage;
            if (flowersUpdate.PreviewImage !== '' && typeof(flowersUpdate.PreviewImage) !== 'undefined') FlowerImage = 'data:image/jpg;base64,' + flowersUpdate.PreviewImage;
            return (
                <div className="fullsize">
                    <div className="container-fluid py-2 px-0">
                        <div className="container">
                            <div className="row">
                                <div className="py-2 col-sm-6">
                                    <h5>Select Your Flowers</h5>


                                    <div className="row no-mobile">
                                        <div className="col-4 p-0">
                                            {flowersLoaded === true && <Fragment>
                                                {flowers.map( (flower, index) => {
                                                    if (selectedFlower === flower.name) {
                                                        return(<div key={index} className="py-4 bg-warning px-3 text-white mb-1 mx-0">{flower.name}</div>);
                                                    } else {
                                                        return(<button key={index} onClick={() => this.changeSelectedFlower(flower)} className="ml-0 mr-1 mb-1 text-left btn btn-block btn-light rounded-0">{flower.name}</button>);
                                                    }
                                                })}
                                            </Fragment>}
                                            {flowersLoaded === false && <Loading /> }
                                        </div>
                                        <div className="col-8 bg-light py-4">
                                            {flowersLoaded === true && <div className="row">
                                                {flowers.map( (flower, index) => {
                                                    if (selectedFlower === flower.name) {
                                                        return (
                                                            <Fragment key={index}>
                                                                {flower.colors.map((flowerColor, index2) => {
                                                                    let qty = 0;
                                                                    if (typeof (selectedFlowers) !== 'undefined') {
                                                                        selectedFlowers.forEach(flowerLoop => {
                                                                            if (flowerLoop.item === flower.name && flowerLoop.color === flowerColor.name) {
                                                                                qty = flowerLoop.qty;
                                                                            }
                                                                        });
                                                                    }
                                                                    return (
                                                                        <div className="col text-center mb-4 flex-grid" key={index2}>
                                                                            <img alt="Flowers" src={flowerColor.image} style={{maxWidth: '120px'}} className="img-fluid"/><br/>
                                                                            <span style={{fontSize: '12px'}}><b>{flowerColor.name}</b></span><br/>
                                                                            {(flowerColor.available === true ) &&
                                                                                <div className="btn-group btn-group-sm" role="group" aria-label="...">
                                                                                    <button
                                                                                        className="btn btn-outline-purple btn-sm"
                                                                                        onClick={(e) => this.startTimer(e, flower.name, flowerColor.name, (qty - 1), flowerColor.image, flowerColor.shopifyId)}
                                                                                    ><FontAwesomeIcon icon={faMinusSquare} /></button>
                                                                                    <button
                                                                                        className="btn btn-outline-purple btn-sm"
                                                                                        onClick={(e) => this.startTimer(e, flower.name, flowerColor.name, (qty + 1), flowerColor.image, flowerColor.shopifyId)}
                                                                                    ><FontAwesomeIcon icon={faPlusSquare} /></button>
                                                                                </div>
                                                                            }
                                                                            {qty !== 0 && <div className="col-12">Qty: {qty*3}</div>}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Fragment>
                                                        );
                                                    } else {
                                                        return (null);
                                                    }
                                                })}
                                            </div>}
                                            {flowersLoaded === false && <Loading /> }
                                        </div>
                                    </div>

                                    {flowersLoaded === true && selectedFlowerSelect.value === '' &&  <div className="row mobile-only">
                                        <div className="col-12 py-0">
                                            <div className="scrolling-wrapper">
                                                {flowerOptions.map((flower, index)=> {
                                                    return (<div className="card text-center m-1 px-2 py-3" key={index}
                                                        onClick={() => this.changeFlower(flower.label)}
                                                    >
                                                        {flower.image !== '' && typeof(flower.image) !== 'undefined' && <img alt="Flowers" src={flower.image} style={{maxWidth: '120px'}} className="img-fluid pb-2"/>}
                                                        <br/><b>{flower.label}</b><br/>
                                                    </div>);
                                                })}
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="row mobile-only">
                                        <div className="col-12 py-0">
                                            {flowersLoaded === true && <div className="scrolling-wrapper">
                                                {flowers.map( (flower, index) => {
                                                    if (selectedFlower === flower.name) {
                                                        return (
                                                            <Fragment key={index}>
                                                                {flower.colors.map((flowerColor, index2) => {
                                                                    let qty = 0;
                                                                    if (typeof (selectedFlowers) !== 'undefined') {
                                                                        selectedFlowers.forEach(flowerLoop => {
                                                                            if (flowerLoop.item === flower.name && flowerLoop.color === flowerColor.name) {
                                                                                qty = flowerLoop.qty;
                                                                            }
                                                                        });
                                                                    }
                                                                    return (
                                                                        <div className="card text-center m-1 px-2 py-3" key={index2}>
                                                                            <img alt="Flowers" src={flowerColor.image} style={{maxWidth: '120px'}} className="img-fluid pb-2"/><br/>
                                                                            <b>{flowerColor.name}</b><br/>
                                                                            {(flowerColor.available === true ) &&
                                                                                <div className="btn-group btn-group-lg" role="group" aria-label="...">
                                                                                    <button
                                                                                        className="btn btn-outline-purple"
                                                                                        onClick={(e) => this.startTimer(e, flower.name, flowerColor.name, (qty - 1), flowerColor.image, flowerColor.shopifyId)}
                                                                                    ><FontAwesomeIcon icon={faMinusSquare} /></button>
                                                                                    <button
                                                                                        className="btn btn-outline-purple"
                                                                                        onClick={(e) => this.startTimer(e, flower.name, flowerColor.name, (qty + 1), flowerColor.image, flowerColor.shopifyId)}
                                                                                    ><FontAwesomeIcon icon={faPlusSquare} /></button>
                                                                                </div>
                                                                            }
                                                                            <div className="col-12 py-2">Qty: {qty*3}</div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Fragment>
                                                        );
                                                    } else {
                                                        return (null);
                                                    }
                                                })}
                                            </div>}
                                            {flowersLoaded === false && <Loading /> }
                                        </div>
                                    </div>

                                    {flowersLoaded === true && selectedFlowerSelect.value === '' &&  <div className="row px-sm-3 px-0 mt-3 mobile-only">
                                        <div className="col-6 py-1">
                                            <button onClick={() => this.cancelChanges()} className="btn btn-danger btn-block rounded-0 m-1"><FontAwesomeIcon icon={faTimes} /></button>
                                        </div>
                                        <div className="col-6 py-1">
                                            <button onClick={() => this.saveChanges(FlowerImage)} className="btn btn-success btn-block rounded-0 m-1"><FontAwesomeIcon icon={faCheck} /></button>
                                        </div>
                                    </div>}
                                    {flowersLoaded === true && selectedFlowerSelect.value !== '' && <div className="row px-sm-3 px-0 mt-3 mobile-only">
                                        <div className="col-12 py-1">
                                            <button onClick={() => this.doneClicked()} className="btn btn-success btn-block rounded-0 m-1">
                                                <FontAwesomeIcon icon={faCheck} />
                                                {' '} Done
                                            </button>
                                        </div>
                                    </div>}

                                    <div className="row px-sm-3 px-0 mt-3 no-mobile">
                                        <div className="col-6 py-1">
                                            <button onClick={() => this.cancelChanges()} className="btn btn-danger btn-block rounded-0 m-1"><FontAwesomeIcon icon={faTimes} /></button>
                                        </div>
                                        <div className="col-6 py-1">
                                            <button onClick={() => this.saveChanges(FlowerImage)} className="btn btn-success btn-block rounded-0 m-1"><FontAwesomeIcon icon={faCheck} /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-first order-md-last py-2 col-sm-6 text-center">
                                    <figure className="figure text-left">
                                        <img className="figure-img img-fluid" alt="Flowers Sample" src={FlowerImage} /><br />
                                        <figcaption className="figure-caption">
                                            Flower Selected: {totalQty} of 36
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else {
            return (<Loading />)
        }
    }
}

BB_Step1.propTypes = {
    flowers: PropTypes.object.isRequired,
    getFlowers: PropTypes.func.isRequired,
    resetFlowerSelection: PropTypes.func.isRequired,
    updateFlowerSelection: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    flowers: state.config.flowers,
    flowersUpdate: state.config.flowersUpdate,
    products: state.config.products
});

export default connect(
    mapStateToProps,
    { getFlowers, resetFlowerSelection, updateFlowerSelection, getProducts }
)(BB_Step1);