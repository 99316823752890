import React, {Component} from 'react';
class Spinner extends Component {
    render() {
        return (
            <div className="text-center">
                Loading ...
            </div>
        )
    }
}
export default Spinner;