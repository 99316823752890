import React, {Component, Fragment} from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faCopy } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import BBStep1 from "./BB_Step1";
import BBStep2 from "./BB_Step2";
import queryString from 'query-string';
import { confirmAlert } from 'react-confirm-alert';
import { API_URL, apiKey } from "../../client/actions/types";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Client from "shopify-buy";

const client = Client.buildClient({
    // LIVE CODE
    storefrontAccessToken: 'c9b98b88961c0862cea503f4e985bdae',
    domain: 'overnight-flowers.myshopify.com'
});
const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzEzMTEwMDY3MjA2NA==";

let customBouquet = [];
customBouquet['full'] = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNDkwOTc1NDE3MDE=";
customBouquet['small'] = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTE3NDI1MzgzODQwNQ==';
customBouquet['medium'] = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTE3NDI1Mzg3MTE3Mw==';
customBouquet['large'] = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTE3NDI1MzkwMzk0MQ==';

export class BB_Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: 0,
            FlowerImage: '/assets/images/bb-logo.jpg',
            selectedFlowers: [],
            selectedFlowersText: '',
            selectedFlowersList: '',
            selectedVase: 'no vase selected',
            totalQty: 0,
            selectionCode: '',
            flowerIds: {},
            showModal: false,
            step: 0,
            saveEmail:'',
            checkoutId: '',
            checkoutUrl:'',
            bouquets: {}
        };
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        let code = values.code;
        let FlowerImage = '/assets/images/flowers.jpg';
        let selectedVase = 'no vase selected';
        this.createCheckout();

        client.collection.fetchWithProducts(collectionId).then((collectionResults) => {
            let bouquets = [];
            collectionResults.products.forEach((collection, index) => {
                if (collection.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNDkwOTc1NDE3MDE=')
                {
                    collection.variants.forEach( (bouquetSize, index) => {
                        bouquets.push({
                            id: bouquetSize.id,
                            title: bouquetSize.title,
                            price: bouquetSize.price
                        });
                    });
                }
            });
            this.setState({bouquets: bouquets, bouquetsLoaded: true });
        });

        if (typeof(code) !== 'undefined' && code !== '')
        {
            axios.post(`${API_URL}/updateFlowersFromCode`, {
                    selectionCode: code,
                    apiKey: apiKey
                })
                .then (res => {
                    if (res.data.Status === 1)
                    {
                        if (res.data.Vase !== '') selectedVase = res.data.Vase;
                        if (res.data.PreviewImage !== '') FlowerImage = res.data.PreviewImage;
                        this.saveFlowerSelection(
                            res.data.selectedFlowers,
                            'data:image/jpg;base64,' + FlowerImage,
                            res.data.totalQty,
                            res.data.FlowerIds,
                            selectedVase
                        );
                        this.setState({ selectedVase: selectedVase });
                    }
                })
                .catch (error => {
                    console.log("Error", error);
                });
        }
    }

    openModal = (step) => {
      this.setState({showModal: true, step: step})
    };
    closeModal = () => {
      this.setState({showModal: false, step: 0})
    };
    AddToCart = () => {
        if (this.state.price === 0) {
            const options = {
                title: 'No flowers selected',
                message: 'You did not add flowers to your custom bouquet',
                buttons: [{
                    label: 'OK'
                }],
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => {},
                onClickOutside: () => {},
                onKeypressEscape: () => {}
            };
            confirmAlert(options);
        } else {
            this.checkoutNow();
        }
    };
    saveForLater = () => {
        //TODO - ADD ERROR CHECKING FOR SAVE FOR LATER

        const options = {
            title: 'Save for later',
            message: 'Save for later',
            buttons: [{
                label: 'OK'
            }],
            customUI: ({ onClose }) => <div className="container bordered border-dark bg-light py-3">
                <div className="row">
                    <div className="col-12"><h4>Save this Bouquet for later</h4></div>
                    <div className="col-12">Enter Your Email Address</div>
                    <div className="col-12"><input type="email" className="form-control" name="saveEmail" onChange={this.onChange} /></div>
                    <div className="col-12 mt-2"><button onClick={() => {this.sendSaveForLater(); onClose();}} className="btn btn-purple btn-pill">Save for Later</button></div>
                </div>
            </div>,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        confirmAlert(options);
    };
    sendSaveForLater = () => {
        const { saveEmail, selectionCode } = this.state;
        axios.post(`${API_URL}/sendSaveForLater`, {
                saveEmail: saveEmail,
                selectionCode: selectionCode,
                apiKey: apiKey
            })
            .then (res => {
                console.log("sendSaveForLater data", res.data);
                if (res.data.Status === 1 || res.data.Status === "true" || res.data.Status === true) {
                    const options = {
                        title: 'Save for later',
                        message: 'Your save link has been sent successfully!',
                        buttons: [{
                            label: 'OK'
                        }],
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        willUnmount: () => {},
                        onClickOutside: () => {},
                        onKeypressEscape: () => {}
                    };
                    confirmAlert(options);
                }
            })
            .catch (error => {
                console.log("Error", error);
            });
    };
    shareProduct = () => {
        const options = {
            title: 'Share this Bouquet',
            message: 'This feature is coming soon, thank you for your patience.',
            buttons: [{
                label: 'OK'
            }],
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: ({ onClose }) => <div className="container bordered border-dark bg-light py-3">
                <div className="row">
                    <div className="col-12"><h4>Share this Bouquet</h4></div>
                    <div className="col-12">To share this bouquet on Social Media copy this URL</div>
                    <div className="col-12">
                        <code>https://bouquetbuilder.overnightflowers.com/?code={this.state.selectionCode}</code>{' '}
                        <button className="btn btn-secondary" onClick={() => this.copyToClipboard('https://bouquetbuilder.overnightflowers.com/?code=' + this.state.selectionCode)}><FontAwesomeIcon icon={faCopy} /></button>
                    </div>
                    <div className="col-12 mt-2">
                        <button onClick={() => {this.sendSaveForLater(); onClose();}} className="btn btn-purple btn-pill">Done</button>
                    </div>
                </div>
            </div>,
            willUnmount: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };
        confirmAlert(options);
    };
    saveFlowerSelection = (selectedFlowers, FlowerImage, totalQty, flowerIds, selectedVase) => {
        let price = 0;
        if (totalQty === 0) {
            price = 0;
        } else if (totalQty <= 3) {
            price = this.state.bouquets[0].price;
        } else if (totalQty <= 24) {
            price = this.state.bouquets[1].price;
        } else if (totalQty > 24) {
            price = this.state.bouquets[2].price;
        }

        let selectedFlowersText = '';
        selectedFlowers.map((flower, index) => {
            selectedFlowersText += '<div>' + (flower.qty * 3) + " " + flower.color + " " + flower.item + '</div>';
            return (null);
        });
        this.setState({
            selectedFlowers: selectedFlowers,
            FlowerImage: FlowerImage,
            showModal: false,
            totalQty:totalQty,
            price: price,
            selectedFlowersText:selectedFlowersText,
            flowerIds: flowerIds
        });
        this.updateCode(flowerIds, selectedVase);
    };
    saveVaseSelection = ( selectedVase ) => {
        this.setState({ showModal: false, step: 0, selectedVase: selectedVase.name, vaseData: selectedVase});
        this.updateCode(this.state.flowerIds, selectedVase.name);
    };
    copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert('URL Copied!');
    };
    updateCode = (selectedFlowers, selectedVase) => {
        let code = '';
        // SET CODE FOR FLOWERS
        if (typeof(selectedFlowers) !== 'undefined') {
            Object.keys(selectedFlowers).map((flower, index) => {
                if (code !== '') code += "-";
                code += flower + "," + selectedFlowers[flower];
                return (null);
            });
        }
        // SET CODE FOR VASE
        if (selectedVase !== '' && typeof(selectedVase) !== 'undefined' && selectedVase !== 'no vase selected') {
            code += "-" + selectedVase.substring(0,1);
        }
        if (code !== '') this.setState({ selectionCode: code});
    };
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    createCheckout = () => {
        // Create an empty checkout
        client.checkout.create().then((checkout) => {
            this.setState({
                checkoutId: checkout.id,
                checkoutUrl: checkout.webUrl
            })
        });
    };
    checkoutNow = () => {
        const {checkoutId, selectedFlowers, selectedVase, checkoutUrl} = this.state;
        let itemAttributes = [];
        let totalQty = 0;
        let itemShopifyId = '';

        // prepare checkout

        // LOOP THROUGH SELECTED FLOWERS
        selectedFlowers.map((items, index) => {
            totalQty += (items.qty * 3);
            itemAttributes.push({
                key: 'Flower ' + (index + 1),
                value: (items.qty * 3) + " " + items.color + " " + items.item
            });
            return (null);
        });

        // add vase
        if (selectedVase !== '' && typeof (selectedVase) !== 'undefined') {
            itemAttributes.push({key: 'Vase', value: selectedVase});
        }

        if (totalQty <= 12) {
            itemShopifyId = customBouquet['small'];
        } else if (totalQty > 12 && totalQty <= 24) {
            itemShopifyId = customBouquet['medium'];
        } else if (totalQty > 24) {
            itemShopifyId = customBouquet['large'];
        }

        const lineItemsToAdd = [{
            variantId: itemShopifyId,
            quantity: 1,
            customAttributes: itemAttributes
        }];

        if (itemShopifyId !== '') {

            client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => { });

            // checkoutUrl
            const options = {
                title: 'Proceed to checkout',
                message: 'When you are ready to purchase click the Checkout Now button.',
                buttons: [{
                    label: 'Checkout Now',
                    onClick: () => this.gotoUrl(checkoutUrl)
                }],
                closeOnEscape: true,
                closeOnClickOutside: true,
                willUnmount: () => {},
                onClickOutside: () => {},
                onKeypressEscape: () => {}
            };
            confirmAlert(options);
        }
    };
    gotoUrl = (url) => {
        window.location.href = url;
    };

    render() {
        let bouquetImage = this.state.FlowerImage;
        const { step, price, showModal, selectedFlowersText, selectedFlowers, selectedVase, selectionCode, totalQty, bouquets, bouquetsLoaded } = this.state;

        let bouquetsHTML = '';
        if (bouquetsLoaded === true && (price === 0 || selectionCode === '')) {
            bouquetsHTML += '<p><br/><b>Bouquet Sizes and Prices</b><br/></p>';
            bouquets.forEach(size => {
                bouquetsHTML += '<h5 class="text-center">' + size.title + ':  $ ' + size.price + '</h5>';
            });
        }

        let step1ButtonColor = 'secondary';
        let step2ButtonColor = 'secondary';

        return (
            <Fragment>
                <Helmet>
                    <title>OvernightFlowers.com - Bouquet Builder</title>
                    <meta name="description" content="OvernightFlowers Bouquet Builder" />
                    <meta name="keywords" content="" />
                    <meta name="robots" content="noindex,nofollow" />
                    <meta name="googlebot" content="noindex,nofollow" />
                    <meta property="og:title" content="OvernightFlowers Bouquet Builder" />
                    <meta property="og:image" content="https://templatebuilderapp.overnightflowers.com/image.php" />
                    <meta property="og:description" content="Build a custom bouquet with our one of a kind bouquet builder" />
                    <meta property="og:type" content="website" />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                <div className="container-fluid container-fluid-xl bg-light pb-sm-5">
                    <div className="container">
                        <div className="row pb-sm-5 py-2">
                            <div className="col-sm-5 text-center py-4">
                                <h4>Customize your Bouquet</h4>
                                <h4>Easy as 1. 2. 3.</h4>
                                <button onClick={() => this.openModal(1)} className={"btn btn-" + step1ButtonColor + " btn-block py-4 text-large mt-sm-5 mt-3"}>1. Select Flowers</button>
                                <button onClick={() => this.openModal(2)} className={"btn btn-" + step2ButtonColor + " btn-block py-4 text-large"}>2. Vases</button>
                                <div dangerouslySetInnerHTML={{__html: bouquetsHTML}} />
                            </div>
                            <div className="order-first order-md-last col-sm-7 py-sm-4 text-center">
                                <img src={bouquetImage} alt="bouquet" className="img-fluid" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 text-center text-danger">
                                <p>All edits are final when you exit the Bouquet Builder so make sure you click save for later.</p>
                            </div>
                        </div>

                        {(price !== 0 || selectionCode !== '') && <div className="row py-5 mx-n4">
                            <div className="col-sm-4">
                                <div className="text-center bg-white rounded px-5 py-2 py-sm-5 threeBoxes">
                                    <h4 className="text-center my-4 text-secondary">Price: ${price}</h4>
                                    <button className="btn btn-dark btn-pill mb-2" onClick={this.AddToCart}>Add to Cart</button><br />
                                    <button className="btn btn-outline-dark btn-pill" onClick={this.saveForLater}>Save for Later</button>
                                    <hr noshade="true" className="mt-5" />
                                    <button onClick={this.shareProduct} className="btn btn-link text-black text-large"><FontAwesomeIcon icon={faShareSquare} /> Share</button>
                                    <hr noshade="true" />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="bg-white rounded text-secondary px-5 py-2 py-sm-5 threeBoxes">
                                    <h4 className="text-center my-4">Selected Options</h4>
                                    <div className="optionTitle">Flowers</div>
                                    {selectedFlowers.map((flower, index) => {
                                        if (flower.item === 'Other Flowers') {
                                            return (<div key={index}>{(flower.qty * 3)} {flower.color}</div>);
                                        } else {
                                            return (<div key={index}>{(flower.qty * 3)} {flower.color} {flower.item}</div>);
                                        }
                                    })}
                                    <div className="optionTitle">Vase</div>
                                    {selectedVase}<br />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="bg-white rounded text-secondary px-5 py-2 py-sm-5 threeBoxes">
                                    <h4 className="text-center my-4">Product Details</h4>
                                    <p>There are no better gifts than personalized gifts. Every bouquet is unique and custom made to your design. Best of all, if your order is placed by 2:30pm EST, we'll deliver your bouquet next business day.</p>
                                </div>
                            </div>
                        </div>}

                        <div className="row py-3 py-sm-5">
                            <div className="col-sm-12 text-center p-1">
                                <img src="/assets/images/promos.png" alt="our bouquets are vase ready" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <Modal show={showModal} onHide={() => this.closeModal()} className="fullsize" dialogClassName="modal-100w">
                        {step === 1 && <BBStep1
                            closeModal={this.closeModal}
                            selectedFlowers={selectedFlowers}
                            totalQty={totalQty}
                            selectedVase={selectedVase}
                            saveFlowerSelection={this.saveFlowerSelection}
                        />}

                        {step === 2 && <BBStep2
                            closeModal={this.closeModal}
                            selectedFlowers={selectedFlowers}
                            selectedVase={selectedVase}
                            totalQty={totalQty}
                            saveVaseSelection={this.saveVaseSelection}
                            bouquetImage={bouquetImage}
                        />}
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

export default (BB_Overview);