import { GET_FLOWERS, UPDATE_FLOWER_SELECTION, API_URL} from './types';
import axios from 'axios/index';
import { apiKey } from "./types";

export const otiInitialize = () => async dispatch => {
    try {
        const res = await axios.get(`http://localhost:10000`, {
            params: {}
        });
        dispatch ({
            type: GET_FLOWERS,
            payload: res.data
        });
    }
    catch (e) {
        console.log("response", e.response);
        console.log("error", e.error);
        console.log("e", e);
    }
};
export const getFlowers = () => async dispatch => {
    try {
        const res = await axios.get(`${API_URL}/getFlowers`, {
            params: {
            }
        });
        dispatch ({
            type: GET_FLOWERS,
            payload: res.data
        });
    }
    catch (e) {
        console.log("response", e.response);
        console.log("error", e.error);
        console.log("e", e);
    }
};
export const updateFlowerSelection = (item, color, qty, selectedFlowers) => async dispatch => {
    try {
        const res = await axios.post(`${API_URL}/updateFlowerSelection`, {
            selectedFlowers: selectedFlowers,
            apiKey: apiKey
        });
        dispatch ({
            type: UPDATE_FLOWER_SELECTION,
            payload: res.data
        });
    }
    catch (e) {
        console.log("e", e);
        //alert('updateFlowerSelection() : the api was not able to load the url');
    }
};
export const resetFlowerSelection = (selectedFlowers) => async dispatch => {
    try {
        const res = await axios.post(`${API_URL}/updateFlowerSelection`, {
            selectedFlowers: selectedFlowers,
            apiKey: apiKey
        });
        dispatch ({
            type: UPDATE_FLOWER_SELECTION,
            payload: res.data
        });
    }
    catch (e) {
        console.log("e", e);
        //alert('updateFlowerSelection() : the api was not able to load the url');
    }
};