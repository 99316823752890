import React, {Component, Fragment} from 'react';
import Loading from "../layout/Loading";
import Client from "shopify-buy";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

const client = Client.buildClient({
    // LIVE CODE
    storefrontAccessToken: 'c9b98b88961c0862cea503f4e985bdae',
    domain: 'overnight-flowers.myshopify.com'
});

//const collectionId = "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NTMxMDEyOTIyMQ==";
const productId    = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzMzgxODE4MzI3NzM=";

export class BB_Step2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            spacing: '16',
            productPrice: '$29.99',
            vases: [],
            selectedVases: [],
            tmpSelectedFlowers: [],
            FlowerImage: '/assets/images/vases.jpg',
            totalQty: 0,
            selectedVase: '',
            selectedVaseName: '',
            vasesLoaded: false,
        };
    }
    componentDidMount() {
        if (this.props.selectedVase !== '' && typeof(this.props.selectedVase) !== 'undefined') {
            this.setState({ selectedVaseName: this.props.selectedVase});
        }

        client.product.fetch(productId).then((product) => {
            product.variants.forEach( (vase, index) => {
                let tmpVase = {
                    shopifyId: vase.id,
                    name: vase.title,
                    image: vase.image.src,
                    price: vase.price,
                    sku: vase.sku,
                    available: vase.available
                };

                if (this.props.selectedVase !== '' && typeof(this.props.selectedVase) !== 'undefined') {
                    this.setState({ vases: [...this.state.vases, tmpVase], selectedVase: tmpVase });
                } else {
                    this.setState({ vases: [...this.state.vases, tmpVase] });
                }
            });
            this.setState({ vasesLoaded: true });
        });
    }
    handleClick = (e, vase) => {
        e.preventDefault();
        this.setState({selectedVaseName: vase.name, selectedVase: vase});
    };
    closeModal = () => {
      this.props.closeModal();
    };

    mouseOver = (e) => {
        e.preventDefault();
    };
    saveChanges = () => {
        this.props.saveVaseSelection( this.state.selectedVase );
    };

    render() {
        const { vases, vasesLoaded, selectedVaseName } = this.state;
        return (<div className="fullsize">
            <div className="container-fluid py-2 px-0">
                <div className="container">
                    <div className="row">
                        <div className="py-2 col-sm-4 p-0 m-0">
                            <h4 className="text-center">Select a Vase</h4>
                            <div className="row p-0 m-0">
                                {vasesLoaded === true && <Fragment>
                                    {vases.map( (vase, index) => {
                                        let vaseClick = '';
                                        if (selectedVaseName === vase.name) {
                                            vaseClick = ' vaseHoverActive';
                                        }

                                        return (<div key={index} className="col-12 text-center">
                                            <div
                                                className={"bg-light mb-2 rounded p-3 vaseHover " + vaseClick}
                                                id={"vase_" + index}
                                                key={index}
                                                onClick={(e) => this.handleClick(e, vase )}
                                            >
                                                <img alt="Vases" src={vase.image} style={{maxWidth: '125px'}} className="img-fluid rounded"/><br/>
                                                <h4 className="mt-2 mb-0">{vase.name}</h4>
                                            </div>
                                        </div>);
                                    })}
                                </Fragment>}
                                {vasesLoaded === false && <Loading /> }
                            </div>
                            <div className="row px-sm-3 px-0 mt-3">
                                <div className="col-6 py-1">
                                    <button onClick={() => this.closeModal()} className="btn btn-danger btn-block rounded-0 m-1"><FontAwesomeIcon icon={faTimes} /></button>
                                </div>
                                <div className="col-6 p-1">
                                    <button onClick={() => this.saveChanges()} className="btn btn-success btn-block rounded-0 m-1"><FontAwesomeIcon icon={faCheck} /></button>
                                </div>
                            </div>
                        </div>
                        <div className="order-first order-md-last py-2 col-sm-8 text-center">
                            <figure className="figure text-left">
                                <img className="figure-img img-fluid" alt="Flowers Sample" src={this.props.bouquetImage} /><br />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default (BB_Step2);